import React, {useEffect, useState} from 'react';
import {client, urlFor} from "../client";
import { Link } from "react-router-dom";
import {AppWrap, MotionWrap} from "../wrapper";
import { images } from '../constants';

const Projects = () => {
    const [events, setEvents] = useState([]);

    useEffect(() => {
        const query = '*[_type == "events"]';

        client.fetch(query).then((data) => {
            setEvents(data);
        });
    }, []);

    return (
        <>
            <section className="section events">
                <img className="events__bg" src={images.bg} alt="img" />
                <div className="container">
                    <div className="row margin-bottom">
                        <div className="col-12">
                            <div className="heading heading--primary heading--center"><span
                                className="heading__pre-title">Events</span>
                                <h2 className="heading__title"><span>Values</span> <span>we celebrate</span></h2>
                                <p className="no-margin-bottom">Discover the upcoming events organized by our association and join us in promoting education, culture, and humanitarian values through a rich variety of activities.</p>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        {events.map((event, index) => (
                        <div className="col-md-6 col-lg-4" key={index}>
                            <div className="event-item">
                                <div className="event-item__img">
                                    {event.imgUrl ? <img className="img--bg" src={urlFor(event.imgUrl)} alt={event.title}/> : ''}
                                </div>
                                <div className="event-item__content">
                                    <h6 className="event-item__title">
                                        <Link to={"/events/" + event.slug.current} key={event.slug.current} target="_blank">{event.title}</Link>
                                    </h6>
                                    <p><b>{event.date}</b></p>
                                </div>
                            </div>
                        </div>
                        ))}
                    </div>
                </div>
            </section>
        </>

    );
};

export default AppWrap(
    MotionWrap(Projects),
    'events',
);
