import React, {useEffect, useState} from 'react';
import {client, urlFor} from "../client";

import {motion} from "framer-motion";
import {PortableText} from '@portabletext/react'
import {NavbarProject} from "../components";
import Footer from "../components/Footer/Footer";
import Aside from "../components/Navbar/Aside";
import Navbar from "../components/Navbar/Navbar";

const AboutPage = () => {

    const [abouts, setAbouts] = useState([]);

    useEffect(() => {
        const query = '*[_type == "aboutpage"]';

        client.fetch(query).then((data) => {
            setAbouts(data);
        });
    }, []);

    return (
        <>
            <div className="page-wrapper">
                <Aside />
                <Navbar />
                <main className="main">
                    {abouts.map((about) => (
                        <div key={about.slug}>
                            <section className="section about-us" id="about">
                                <div className="container">
                                    <div className="row align-items-center">
                                        <div className="col-lg-6 col-xl-5">
                                            <div className="img-box">
                                                {/*<img className="img--layout" src="img/about_layout-reverse.png" alt="img"/>*/}
                                                {about.imgUrl ? <img src={urlFor(about.imgUrl)} alt={about.heading} className="img-box__img" style={{maxWidth: '470px'}}/> : ''}
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-xl-6 offset-xl-1">
                                            <div className="heading heading--primary"><span
                                                className="heading__pre-title">{about.label}</span>
                                                <h2 className="heading__title"><span>{about.heading}</span></h2>
                                            </div>
                                            <PortableText value={about.description}/>
                                        </div>
                                    </div>
                                </div>
                            </section>
                            <section className="section video-block no-padding-top">
                                {about.videos.map((video) => (
                                <div className="container">
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="video-frame">
                                                {video.vignette ? <img src={urlFor(video.vignette)} alt={video.videoLabel} className="img--bg" /> : ''}
                                                <a className="video-trigger video-frame__trigger" href={video.url} target="_blank" rel="noreferrer">
                                                <span className="video-frame__icon">
                                                <i className="fa fa-play" aria-hidden="true"/>
                                                </span>
                                                <span className="video-frame__text">Watch our video</span></a>
                                                {/*<img className="video-frame__img-layout" src="img/video_frame-layout.png" alt="layout"/>*/}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                ))}
                            </section>
                            <motion.section
                                whileInView={{ y: [100, 50, 0], opacity: [0, 0, 1] }}
                                transition={{ duration: 0.5 }}
                                className="section team"
                            >
                                {about.team.map((teaminfo) => (
                                    <div className="container" key={teaminfo.title} id="team">
                                        <div className="row">
                                            <div className="col-12">
                                                <div className="heading heading--primary"><span
                                                    className="heading__pre-title">{teaminfo.title}</span>
                                                    <h2 className="heading__title no-margin-bottom"><span>{teaminfo.tagline}</span></h2>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row margin-bottom">
                                            {teaminfo.teammembers.map((member, index) => (
                                            <div className="col-sm-6 col-lg-4 col-xl-3" key={member.role + index}>
                                                <div className="team-item team-item--rounded">
                                                    <div className="team-item__img-holder">
                                                        {/*<img className="img--layout" src="img/team_1.png" alt="layout"/>*/}
                                                        <div className="team-item__img">
                                                            {member.imgUrl ? <img src={urlFor(member.imgUrl)} alt={member.role} className="img--bg" /> : ''}
                                                        </div>
                                                    </div>
                                                    <div className="team-item__description">
                                                        <div className="team-item__name">{member.firstname} {member.lastname}</div>
                                                        <div className="team-item__position">{member.role}</div>
                                                    </div>
                                                </div>
                                            </div>
                                            ))}
                                        </div>
                                    </div>
                                ))}
                            </motion.section>
                        </div>
                    ))}
                </main>
                <Footer />
            </div>
        </>
    );
};

export default AboutPage;
